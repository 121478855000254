import React from 'react'
import { Helmet } from 'react-helmet'
import CountUp from 'react-countup'
import { Form, Row, Col } from 'antd'
import { Waypoint } from 'react-waypoint'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import PropTypes from 'prop-types'

import { Banner, FadeInTop } from '@client/components/index'
import { AppContainer } from '@client/containers/index'

import styles from './Home_bak.styl'
import homeImage from './images/home.jpg'
import alarmImage from './images/alarm.png'
import flagImage from './images/flag.png'
import groupImage from './images/group.png'
import personImage from './images/person.png'
import batmanImage from './images/batman.png'
import docImage from './images/doc.png'
import noteImage from './images/note.png'

const classicTasks = {
  task1: { image: './images/classic-task-1.jpg', title: '寫下人生清單', open: false, show: false },
  task2: { image: './images/classic-task-2.jpg', title: '揪團捐血', open: false, show: false },
  task3: { image: './images/classic-task-3.jpg', title: '街頭表演', open: false, show: false },
  task4: { image: './images/classic-task-4.jpg', title: '養成運動習慣', open: false, show: false },
  task5: {
    image: './images/classic-task-5.jpg',
    title: '在街頭上給予陌生人免費擁抱',
    open: false,
    show: false,
  },
  task6: {
    image: './images/classic-task-6.jpg',
    title: '在街頭送餐給街友',
    open: false,
    show: false,
  },
  task7: {
    image: './images/classic-task-7.jpg',
    title: '訪問傳統老行業',
    open: false,
    show: false,
  },
  task8: { image: './images/classic-task-8.jpg', title: '搭便車', open: false, show: false },
  task9: {
    image: './images/classic-task-9.jpg',
    title: '與外國人做朋友',
    open: false,
    show: false,
  },
  task10: {
    image: './images/classic-task-10.jpg',
    title: '找到身旁最美麗的風景',
    open: false,
    show: false,
  },
  task11: { image: './images/classic-task-11.jpg', title: '養成新習慣', open: false, show: false },
  task12: {
    image: './images/classic-task-12.jpg',
    title: '在城市發現故事',
    open: false,
    show: false,
  },
  task13: {
    image: './images/classic-task-13.jpg',
    title: '記錄父母成長故事',
    open: false,
    show: false,
  },
  task14: { image: './images/classic-task-14.jpg', title: '零元旅行', open: false, show: false },
  task15: {
    image: './images/classic-task-15.jpg',
    title: '找回曾經冷戰的朋友',
    open: false,
    show: false,
  },
  task16: {
    image: './images/classic-task-16.jpg',
    title: '去喜歡的非營利組織當志工',
    open: false,
    show: false,
  },
}

Object.keys(classicTasks).forEach((taskIndex) => {
  const image = require(`${classicTasks[taskIndex].image}`)
  classicTasks[taskIndex].image = image
})

const duration = 500

class Home extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      classicTasks,
    }
  }

  onMouseEnter = (event) => {
    event.stopPropagation()
    const newState = { ...classicTasks }
    newState[event.currentTarget.dataset.taskIndex].show = true
    this.setState({ classicTasks: newState })
  }

  onMouseLeave = (event) => {
    event.stopPropagation()
    const newState = { ...classicTasks }
    newState[event.currentTarget.dataset.taskIndex].show = false
    this.setState({ classicTasks: newState })
  }

  onClick = (event) => {
    const newState = { ...classicTasks }
    newState[event.currentTarget.dataset.taskIndex].open = true
    this.setState({ classicTasks: newState })
  }

  hideModal = (event, taskIndex) => {
    const newState = { ...classicTasks }
    newState[taskIndex].open = false
    this.setState({ classicTasks: newState })
  }

  render() {
    const { t } = this.props
    return (
      <AppContainer style={{ paddingTop: 0 }}>
        <Helmet>
          <title>{t('首頁')}</title>
        </Helmet>

        <Banner
          backgroundImage={homeImage}
          logoFlex="flex-end"
          title={`${t('我聽了很多人的故事，卻不知道怎麼開啟自己的，直到他們帶我去')}...`}
        />

        <Waypoint
          bottomOffset="50px"
          onEnter={() => {
            this.setState({ showSection1: true })
          }}
        />

        <section
          className={`${styles.section} ${styles.normalTheme}`}
          style={{ display: this.state.showSection1 ? 'block' : 'none' }}
        >
          <FadeInTop show={this.state.showSection1} duration={duration} delay={0}>
            <Row type="flex" justify="center">
              <Col>
                <h1 className={`${styles.title}`}>{t('挑戰賽介紹')}</h1>
              </Col>
            </Row>
          </FadeInTop>

          <Row type="flex" justify="center">
            <Col className={`${styles.challengeDescription}`} sm={12} md={8} xl={5}>
              <FadeInTop show={this.state.showSection1} duration={duration} delay={200}>
                <img src={alarmImage} />
                <p>{t('一個月的探索')}</p>
              </FadeInTop>
            </Col>

            <Col className={`${styles.challengeDescription}`} sm={12} md={8} xl={5}>
              <FadeInTop show={this.state.showSection1} duration={duration} delay={400}>
                <img src={flagImage} />
                <p>{t('一連串的任務')}</p>
              </FadeInTop>
            </Col>

            <Col className={`${styles.challengeDescription}`} sm={12} md={8} xl={5}>
              <FadeInTop show={this.state.showSection1} duration={duration} delay={600}>
                <img src={groupImage} />
                <p>{t('三人一組')}</p>
              </FadeInTop>
            </Col>
          </Row>

          <Row className={`${styles.section} ${styles.blueTheme}`} type="flex" justify="center">
            <Col xs={18} sm={14}>
              <FadeInTop show={this.state.showSection1} duration={duration} delay={1000}>
                <article className={styles.article}>
                  {t('城市浪人流浪挑戰賽是一場走出舒適圈的競賽，')}
                  <br />
                  {t('在這場挑戰賽中，需三個人一組報名，')}
                  <br />
                  {t('並在有限的賽期間盡量嘗試完成所有任務。')}
                  <br />
                  <br />
                  {t('我們不去較勁誰的專業知識比較豐富、也不是排序誰的資源豐厚，')}
                  <br />
                  {t('而是在我們感受世界的路途上，')}
                  <br />
                  {t('看誰更願意跨出第一步去嘗試理解世界與自我的關係，')}
                  <br />
                  {t('並且比賽誰對世界保有更多的好奇心、同理心與改變的勇氣。')}
                  <br />
                  <br />
                  {t(
                    '希望在執行任務的過程中，參賽的青年們能夠打破由於平時缺乏溝通與接觸社會帶來的迷惘。',
                  )}
                  <br />
                </article>
              </FadeInTop>
            </Col>
          </Row>

          {this.state.showSection1 && (
            <Waypoint
              onEnter={() => {
                this.setState({ showSection2: true })
              }}
            />
          )}
        </section>

        <section
          className={`${styles.section} ${styles.normalTheme}`}
          style={{ display: this.state.showSection2 ? 'block' : 'none' }}
        >
          <Row type="flex" justify="center">
            <Col className={styles.challengeDescription} xs={12} sm={5}>
              <FadeInTop show={this.state.showSection2} duration={duration} delay={200}>
                <img src={personImage} />
                <p>{t('自我覺察')}</p>
              </FadeInTop>
            </Col>

            <Col className={styles.challengeDescription} xs={12} sm={5}>
              <FadeInTop show={this.state.showSection2} duration={duration} delay={400}>
                <img src={batmanImage} />
                <p>{t('冒險挑戰')}</p>
              </FadeInTop>
            </Col>

            <Col className={styles.challengeDescription} xs={12} sm={5}>
              <FadeInTop show={this.state.showSection2} duration={duration} delay={600}>
                <img src={docImage} />
                <p>{t('連結再造')}</p>
              </FadeInTop>
            </Col>

            <Col className={styles.challengeDescription} xs={12} sm={5}>
              <FadeInTop show={this.state.showSection2} duration={duration} delay={1000}>
                <img src={noteImage} />
                <p>{t('社會參與')}</p>
              </FadeInTop>
            </Col>
          </Row>

          <Row className={`${styles.section} ${styles.blueTheme}`} type="flex" justify="center">
            <Col xs={18} sm={14}>
              <FadeInTop show={this.state.showSection2} duration={duration} delay={1000}>
                <article className={styles.article}>
                  {t('流浪挑戰賽的任務主題分為四大面向，')}
                  <br />
                  {t('包括自我覺察、冒險挑戰、連結再造、社會參與等不同領域。')}
                  <br />
                  {t('四大面向，期盼能為參與者帶來在自我、社會兩條軸線上，')}
                  <br />
                  {t('對於陌生與已知的部份有更全面的了解與感悟。')}
                  <br />
                  <br />
                  {t('每項任務完成後，須繳交要求之成果到賽務系統上，')}
                  <br />
                  {t('主辦單位確認成果後會發放積分，')}
                  <br />
                  {t('在賽期結束後，會頒發流浪紀念品與相關獎勵，')}
                  <br />
                  {t('鼓勵參賽者帶著獎勵走向人生下一個階段的挑戰。')}
                  <br />
                </article>
              </FadeInTop>
            </Col>
          </Row>

          {this.state.showSection2 && (
            <Waypoint
              onEnter={() => {
                this.setState({ showSection3: true })
              }}
            />
          )}
        </section>

        <section
          className={`${styles.section} ${styles.normalTheme}`}
          style={{ display: this.state.showSection3 ? 'block' : 'none' }}
        >
          <Row type="flex" justify="center">
            <h1 className={styles.title}>{t('任務成果')}</h1>
          </Row>

          <Row type="flex" justify="center">
            <Col className={styles.statistic} xs={16} sm={12} md={5}>
              <FadeInTop show={this.state.showSection3} duration={duration} delay={0}>
                {this.state.showSection3 && (
                  <CountUp
                    className={styles.number}
                    useGrouping
                    separator=","
                    start={0}
                    end={60}
                    duration={10}
                  />
                )}
                <p className={styles.description}>{t('挑戰賽')}</p>
              </FadeInTop>
            </Col>

            <Col className={styles.statistic} xs={16} sm={12} md={5}>
              <FadeInTop show={this.state.showSection3} duration={duration} delay={200}>
                {this.state.showSection3 && (
                  <CountUp
                    useGrouping
                    separator=","
                    className={styles.number}
                    start={0}
                    end={80}
                    duration={10}
                  />
                )}
                <p className={styles.description}>{t('大專院校')}</p>
              </FadeInTop>
            </Col>

            <Col className={styles.statistic} xs={16} sm={12} md={5}>
              <FadeInTop show={this.state.showSection3} duration={duration} delay={600}>
                {this.state.showSection3 && (
                  <CountUp
                    useGrouping
                    separator=","
                    className={styles.number}
                    start={0}
                    end={10000}
                    duration={10}
                  />
                )}
                <p className={styles.description}>{t('青年')}</p>
              </FadeInTop>
            </Col>

            <Col className={styles.statistic} xs={16} sm={12} md={5}>
              <FadeInTop show={this.state.showSection3} duration={duration} delay={800}>
                {this.state.showSection3 && (
                  <CountUp
                    useGrouping
                    separator=","
                    className={styles.number}
                    start={0}
                    end={35000}
                    duration={10}
                  />
                )}
                <p className={styles.description}>{t('故事')}</p>
              </FadeInTop>
            </Col>
          </Row>

          {this.state.showSection3 && (
            <Waypoint
              onEnter={() => {
                this.setState({ showSection4: true })
              }}
            />
          )}
        </section>

        <section
          className={`${styles.section} ${styles.normalTheme}`}
          style={{ display: this.state.showSection4 ? 'block' : 'none' }}
        >
          <Row type="flex" justify="center">
            <FadeInTop show={this.state.showSection4} duration={duration} delay={0}>
              <h1 className={styles.title}>{t('經典任務')}</h1>
            </FadeInTop>
          </Row>

          <Row type="flex" justify="center">
            {Object.keys(this.state.classicTasks).map((taskIndex, index) => (
              <Col
                key={taskIndex}
                className={styles.classicTask}
                data-task-index={taskIndex}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onClick={this.onClick}
                xs={12}
                sm={6}
              >
                <FadeInTop show={this.state.showSection4} duration={duration} delay={index * 200}>
                  <div
                    className={styles.image}
                    style={{ backgroundImage: `url(${this.state.classicTasks[taskIndex].image})` }}
                  />

                  <div className={styles.item}>
                    <p className={styles.title}>{t(this.state.classicTasks[taskIndex].title)}</p>
                  </div>
                </FadeInTop>
              </Col>
            ))}
          </Row>

          {/* {this.state.showSection4 ? (
            <Waypoint
              onEnter={() => {
                this.setState({ showSection5: true });
              }}
            />
          ) : null} */}
        </section>

        {/* <section
          className={`${styles.section} ${styles.normalTheme}`}
          style={{ display: this.state.showSection5 ? 'block' : 'none' }}
        >
          <Row type="flex" justify="center">
            <FadeInTop show={this.state.showSection5} duration={duration} delay={0}>
              <h1 className={styles.section_title}>合作聯繫</h1>
            </FadeInTop>
          </Row>

          <Row type="flex" justify="center">
            <Col xs={20} sm={10}>
              <FadeInTop show={this.state.showSection5} duration={duration} delay={200}>
                <div className={styles.contactInfo}>
                  <div className={styles.contactInfo__pair}>
                    <h5>聯絡信箱</h5>
                    <p>service@citywanderer.org</p>
                  </div>
                  <div className={styles.contactInfo__pair}>
                    <h5>電話</h5>
                    <p>0909 684 793</p>
                  </div>
                  <div className={styles.contactInfo__pair}>
                    <h5>住址</h5>
                    <p>100 台北市大安區羅斯福路三段283巷21弄3號1樓</p>
                  </div>
                </div>

                <Form onSubmit={this.handleSubmit}>
                  <Item label="稱謂">
                    {getFieldDecorator('account', {
                      rules: [{ required: true, message: '此為必填欄位' }],
                    })(
                      <Input
                        ref={(input) => {
                          this.accountInput = input;
                        }}
                        placeholder="帳號"
                      />,
                    )}
                  </Item>

                  <Item label="聯繫方式">
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: '此為必填欄位' }],
                    })(<Input type="password" placeholder="密碼" />)}
                  </Item>

                  <Item label="來信原因">
                    <Select placeholder="來信原因" onChange={this.handleReasonSelectChange}>
                      <Option value="演講邀約">演講邀約</Option>
                      <Option value="合作邀約">合作邀約</Option>
                      <Option value="工作坊設計邀約">工作坊設計邀約</Option>
                      <Option value="挑戰賽授權邀約">挑戰賽授權邀約</Option>
                      <Option value="其他邀約">其他邀約</Option>
                    </Select>
                  </Item>

                  <Item label="內容">
                    <TextArea autosize={{ minRows: 6 }} />
                  </Item>

                  <Item>
                    <Button className="primary" htmlType="submit" style={{ width: '100%' }}>
                      送出
                    </Button>
                  </Item>
                </Form>
              </FadeInTop>
            </Col>

            <Col xs={20} sm={10}>
              <FadeInTop
                show={this.state.showSection5}
                duration={duration}
                delay={200}
                style={{ height: '100%' }}
              >
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.418378129444!2d121.5293295150061!3d25.019871983978913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a98933780877%3A0x83bbda9a99d5c83e!2zMTA25Y-w5YyX5biC5aSn5a6J5Y2A576F5pav56aP6Lev5LiJ5q61Mjgz5be3MjHlvIQz6Jmf!5e0!3m2!1szh-TW!2stw!4v1504985937801"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ border: 0, padding: '2em' }}
                />
              </FadeInTop>
            </Col>
          </Row>
        </section> */}
      </AppContainer>
    )
  }
}

export default compose(
  withTranslation(),
  Form.create(),
)(Home)
