import React from 'react'
import { Helmet } from 'react-helmet'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import { AppContainer } from '@client/containers/index'

import styles from './Home.styl'
import logoImage from './images/title.png'
import section_2_1Image from './images/section2-1.png'
import section_2_2Image from './images/section2-2.png'
import event_category_1Image from './images/event-category-1.jpg'
import event_category_2Image from './images/event-category-2.jpg'
import event_category_3Image from './images/event-category-3.jpg'
import allowImage from './images/allow.png'
import currEventImage from './images/current-event.jpg'

class Home extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.props
    return (
      <AppContainer style={{ paddingTop: 0 }}>
        <Helmet>
          <title>{t('首頁')}</title>
        </Helmet>

        <Row>
          <Col>
            <div className={styles.banner}>
              <div className={styles.content}>
                <div>Inspire People, Cultivate the World.</div>
                <div>翹掉不滿意的生活，找尋一輩子的熱情所在。</div>
                <div>
                  <img className={styles.logo} src={logoImage} alt="logo" />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className={styles.section2}>
          <Col className={styles.section2}>
            <Row type="flex" justify="center" align="middle">
              <Col xs={24} sm={24} md={{ span: 10, offset: 2 }} lg={{ span: 10, offset: 2 }}>
                <div className={styles.textBox}>
                  <div className={styles.title}>我們的青年世代</div>
                  <div className={styles.content}>
                    在台灣，每十個青年裡，就有四個為自己的未來感到沒自信與不知所措，而有一個連自己的未來在哪裡都不知道。
                  </div>
                  <div className={styles.content}>
                    我們都知道，青年是國家未來的希望，是社會創新的進步來源，但是，如果年輕一代對於自己的將來都感到缺乏希望與熱情，又如何期待他們能為社會上的需求產生有意義的行動、為他人和社會帶
                    來正面的改變呢?
                  </div>
                  <div className={styles.content}>
                    城市浪人從2013年開始，透過自己創造以及與其他機構共同籌劃執行為青年服務的大小計畫，期待能協助青年們跳脫過往成長的框架，進而找到對自己以及社會的正面改變。
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={{ span: 10, offset: 2 }} lg={{ span: 10, offset: 2 }}>
                <div>
                  <img className={styles.imageBox} src={section_2_1Image} alt="section_2_1" />
                </div>
              </Col>
            </Row>
          </Col>
          <Col className={styles.section2}>
            <Row type="flex" justify="start" align="middle">
              <Col
                xs={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                md={{ span: 10, order: 1 }}
                lg={{ span: 10, order: 1 }}
              >
                <div>
                  <img className={styles.imageBox} src={section_2_2Image} alt="section_2_2" />
                </div>
              </Col>
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 10, offset: 2, order: 2 }}
                lg={{ span: 10, offset: 2, order: 2 }}
              >
                <div className={styles.textBox}>
                  <div className={styles.title}>讓青年變得更好的方法</div>
                  <div className={styles.content}>
                    「教育，應該是讓每個人在自我實現的同時，都可以跟社會上其他人連在一起。」
                  </div>
                  <div className={styles.content}>
                    2013年秋天，一群大學生發現了年輕人缺乏自我認知與社會關懷這兩大問題，流浪挑戰賽由此而生。
                  </div>
                  <div className={styles.content}>
                    經過多年的嘗試與修正挑戰賽的模式，我們逐漸發現有越來越多人跟我們一樣關心青年探索的議題，卻不知道該從何做起，於是近年來，城市浪人延續流浪挑戰賽的模式，開啟與公部門、私人企業以及校園的合作。
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className={styles.eventCategory}>
              <div
                className={styles.bkg}
                style={{ backgroundImage: `url(${event_category_1Image})` }}
              />
              <div className={styles.textContent}>
                <div className={styles.category}>自我成長</div>
                <div className={styles.title}>流浪挑戰賽</div>
                <div className={styles.content}>一場跟自己挑戰的比賽</div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className={styles.eventCategory}>
              <div
                className={styles.bkg}
                style={{ backgroundImage: `url(${event_category_2Image})` }}
              />
              <div className={styles.textContent}>
                <div className={styles.category}>職涯探索</div>
                <div className={styles.title}>百工日記</div>
                <div className={styles.content}>帶你體驗101種職人生活</div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className={styles.eventCategory}>
              <div
                className={styles.bkg}
                style={{ backgroundImage: `url(${event_category_3Image})` }}
              />
              <div className={styles.textContent}>
                <div className={styles.category}>教材開發</div>
                <div className={styles.title}>教育種籽計畫</div>
                <div className={styles.content}>一堂線下人生遊戲體驗課</div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={24}>
            <div className={styles.eventCategoryMore}>
              <div className={styles.text}>
                更多協助青年的方法
                <img className={styles.allow} src={allowImage} alt="allow" />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <img className={styles.currentEvent} src={currEventImage} alt="current-event" />
          </Col>
        </Row>
      </AppContainer>
    )
  }
}

export default compose(withTranslation())(Home)
